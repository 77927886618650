import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Archive from '../components/Archive/Archive'
import PageMeta from '../components/PageMeta/PageMeta'
import { type ILittleBlackBookArchiveData } from '../graphql/queries/LittleBlackBookArchiveQuery'

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      lBBCategory(id: $id) {
        description
        id
        name
        uri
        databaseId
        seo {
          title
          metaDesc
        }
        link
        parent {
          node {
            name
            uri
          }
        }
        children(where: { hideEmpty: true }) {
          nodes {
            name
            uri
          }
        }
      }
      generalSettings {
        title
        siteId
        url
      }
    }
  }
`

interface LittleBlackBookArchiveProps {
  data: ILittleBlackBookArchiveData
}

const LittleBlackBookArchive = ({ data }: LittleBlackBookArchiveProps) => {
  const { lBBCategory, generalSettings } = data.wpgraphql
  const postType = {
    id: 'lbb',
    title: 'Little Black Book',
    uri: '/little-black-book'
  }

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title:
            lBBCategory.seo?.title ??
            "Muddy Stilettos' Little Black Book of Best Businesses",
          description: lBBCategory.seo?.metaDesc
            ? lBBCategory.seo.metaDesc
            : lBBCategory.description ||
              `A tailored list of companies we think you should know about. Whether you're looking for pubs, hotels, bars or wedding venues, we have the best in the biz.`,
          url: lBBCategory.link
        }}
      />
      <Header />
      <Archive
        category={lBBCategory}
        postType={postType}
        parentCategory={lBBCategory.parent?.node}
        pagination={1}
        siteId={generalSettings.siteId}
      />
      <Footer />
    </>
  )
}

export default LittleBlackBookArchive
